import React, { useEffect, useState } from "react";
import ScrollToTopLink from "./ScrollToTopLink";
import AppLoader from "./AppLoader/index";
import Fparticles from "./Fparticles";

const Header = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [scrollY, setScroll] = useState(false);
  const [navExpand, setNavExpand] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scroll = window.scrollY;
      setScroll(scroll);
      if (scroll >= 1) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const windowWidth = () => {
      if (window.innerWidth > 991) {
        setNavExpand(false);
      }
    };
    window.addEventListener("resize", windowWidth);

    const handleToggleNav = () => {
      setNavExpand(true);
    };
    const handleToggleNavClose = () => {
      setNavExpand(false);
    };
    const navExpander = document.querySelector(
      ".expand-btn-inner #nav-expander"
    );
    if (navExpander) {
      navExpander.addEventListener("click", (e) => {
        e.preventDefault();
        handleToggleNav();
      });
    }

    const navClose = document.querySelector("#nav-close2");
    if (navClose) {
      navClose.addEventListener("click", (e) => {
        e.preventDefault();
        handleToggleNavClose();
      });
    }
  }, []);
  useEffect(() => {
    const mobileMenu = document.getElementById("mobile-navbar-menu");

    const resizeFix = () => {
      if (window.innerWidth > 991) {
        Array.from(mobileMenu.querySelectorAll("ul")).forEach((ul) => {
          ul.style.display = "block";
        });
        Array.from(mobileMenu.querySelectorAll("ul.sub-menu")).forEach((ul) => {
          ul.style.display = "none";
        });
      }
    };

    const multiTg = () => {
      Array.from(mobileMenu.querySelectorAll(".has-sub")).forEach(
        (menuItem) => {
          menuItem.insertAdjacentHTML(
            "afterbegin",
            '<span class="submenu-button"><em></em></span>'
          );
          menuItem.classList.add("hash-has-sub");
          const submenuButton = menuItem.querySelector(".submenu-button");
          submenuButton.addEventListener("click", () => {
            submenuButton.classList.toggle("submenu-opened");
            const submenu = menuItem.querySelector("ul");
            if (submenu.classList.contains("open-sub")) {
              submenu.classList.remove("open-sub");
              submenu.style.display = "none";
            } else {
              submenu.classList.add("open-sub");
              submenu.style.display = "block";
            }
          });
        }
      );
    };

    multiTg();

    if (mobileMenu) {
      mobileMenu.classList.add("dropdown");
      window.addEventListener("resize", resizeFix);
      resizeFix();
    }

    return () => {
      window.removeEventListener("resize", resizeFix);
    };
  }, []);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate data fetching
    setTimeout(() => setLoading(false), 3000);
  }, []);
  return (
    <div
      id="mobilemenu-expand"
      className={
        navExpand ? "home-paste-color nav-expanded" : "home-paste-color"
      }
    >
      {" "}
      <div className="offwrap"></div>
      <div className="full-width-header">
      <div className="sunilbabu">
      {loading ? <AppLoader /> : <div></div>}
    </div>
        <header
          id="rs-header"
          className="rs-header header-home5-style header-transparent"
        >
          <div className="topbar-area style1">
            <div className="container-fluid custom">
              <div className="row y-middle">
                <div className="col-lg-8">
                  <div className="topbar-contact">
                    <ul>
                      <li>
                        <i className="flaticon-email"></i>

                        <a href="mailto:info@hiltonglobal.in">
                          info@hiltonglobal.in
                        </a>
                      </li>

                      <li>
                        <i className="flaticon-call"></i>

                        <a href="tel:+917993606894">+91 79936 06894</a>
                      </li>

                      <li>
                        <i className="flaticon-location"></i>
                        S.R Nagar, Hyderabad-38.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="col-lg-4 text-right">
                  <div className="toolbar-sl-share">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/hiltonsoftwarecompany/"
                          target="_blank"
                        >
                          <i className="fa fa-facebook"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://twitter.com/Hilton_global"
                          target="_blank"
                        >
                          <i className="fa fa-twitter"></i>
                        </a>
                      </li>

                      <li>
                        <a
                          href="https://in.linkedin.com/company/hilton-global"
                          target="_blank"
                        >
                          <i className="fa fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/hiltonsoftwaretechnologies/?hl=en"
                          target="_blank"
                        >
                          <i className="fa fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              isSticky
                ? "menu-area menu-sticky sticky"
                : "menu-area menu-sticky"
            }
          >
            <div className="container-fluid custom">
              <div className="row-table">
                <div className="col-cell header-logo">
                  <div className="logo-area">
                    <ScrollToTopLink to="/">
                      <img
                        className="normal-logo"
                        src="/assets/images/hiltonlogo.webp"
                        alt="logo"
                      />
                      <img
                        className="sticky-logo"
                        src="/assets/images/hiltonlogo.webp"
                        alt="logo"
                      />
                    </ScrollToTopLink>
                  </div>
                </div>
                <div className="col-cell">
                  <div className="rs-menu-area">
                    <div className="main-menu">
                      <nav className="rs-menu hidden-md">
                        <ul className="nav-menu">
                          <li className="">
                            <ScrollToTopLink to="/">Home</ScrollToTopLink>
                          </li>

                          <li className="">
                            <ScrollToTopLink to="/aboutus">
                              About Us
                            </ScrollToTopLink>
                          </li>

                          <li className="menu-item-has-children has-sub">
                            <ScrollToTopLink to="/services">
                              Services
                            </ScrollToTopLink>
                            <ul className="sub-menu">
                              <li>
                                <ScrollToTopLink to="/services/fullstack-development">
                                  Full stack development
                                </ScrollToTopLink>
                              </li>
                              <li>
                                <ScrollToTopLink to="/services/e-commerce-development">
                                  e-Commerce development
                                </ScrollToTopLink>
                              </li>

                              <li>
                                <ScrollToTopLink to="/services/mobile-app-development">
                                  Mobile apps development
                                </ScrollToTopLink>
                              </li>

                              <li>
                                <ScrollToTopLink to="/services/web-app-development">
                                  Web application development
                                </ScrollToTopLink>
                              </li>

                              <li>
                                <ScrollToTopLink to="/services/hire-a-developer">
                                  Hire a developer
                                </ScrollToTopLink>
                              </li>

                              <li>
                                <ScrollToTopLink to="/services/technical-support">
                                  Technical support service
                                </ScrollToTopLink>
                              </li>
                              <li>
                                <ScrollToTopLink to="/services/bi-analytics">
                                  Data & Analytics
                                </ScrollToTopLink>
                              </li>
                              <li>
                                <ScrollToTopLink to="/services/ai-ml">
                                  AI & ML
                                </ScrollToTopLink>
                              </li>
                              <li>
                                <ScrollToTopLink to="/services/erp-solutions">
                                  ERP Solutions
                                </ScrollToTopLink>
                              </li>
                            </ul>{" "}
                          </li>

                          {/* <li>
                            <ScrollToTopLink to="/blog" aria-hidden="true">
                              Blog
                            </ScrollToTopLink>
                          </li> */}
                          <li className="">
                            <ScrollToTopLink to="/careers">
                              Careers
                            </ScrollToTopLink>
                          </li>
                          <li className="">
                            <ScrollToTopLink to="/contact">
                              Contact
                            </ScrollToTopLink>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
                <div className="col-cell">
                  <div className="expand-btn-inner">
                    <ul>
                      <li className="humburger">
                        <a id="nav-expander" className="nav-expander bar">
                          <div className="bar">
                            <span className="dot1"></span>
                            <span className="dot2"></span>
                            <span className="dot3"></span>
                            <span className="dot4"></span>
                            <span className="dot5"></span>
                            <span className="dot6"></span>
                            <span className="dot7"></span>
                            <span className="dot8"></span>
                            <span className="dot9"></span>
                          </div>
                        </a>
                      </li>
                      <li>
                      <div className="btn-wrap">
                        <a
                          target="_self"
                          className="fl-button yellow-bg"
                          href="/get-a-quote"
                          tabIndex="0"
                          previewlistener="true"
                        >
                          <span className="fl-button-text">Get a quote</span>
                          <i className="fl-button-icon fl-button-icon-after fa fa-long-arrow-right"></i>
                          
                        </a>
                      </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <nav className="right_menu_togle hidden-md">
            <div className="close-btn">
              <a id="nav-close" className="nav-close">
                <div className="line">
                  <span className="line1"></span>

                  <span className="line2"></span>
                </div>
              </a>
            </div>

            <div className="canvas-logo">
              <ScrollToTopLink to="/">
                <img src="/assets/images/hiltonlogo.png.webp" alt="logo" />
              </ScrollToTopLink>
            </div>

            <div className="offcanvas-text">
              <p>
                We denounce with righteous indig nation in and dislike men who
                are so beguiled and to demo realized, that they data forest see
                best business consulting wordpress theme 2021.
              </p>
            </div>

            <div className="media-img">
              <img src="/assets/images/off2.webp" alt="Images" />
            </div>

            <div className="canvas-contact">
              <div className="address-area">
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-location"></i>
                  </div>

                  <div className="info-content">
                    <h4 className="title">Address</h4>

                    <em>fremont CA - 94538</em>
                  </div>
                </div>

                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-email"></i>
                  </div>

                  <div className="info-content">
                    <h4 className="title">E-mail</h4>

                    <em>
                      <a href="mailto:info@hiltonglobal.in">
                        {" "}
                        info@hiltonglobal.in
                      </a>
                    </em>
                  </div>
                </div>

                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-call"></i>
                  </div>

                  <div className="info-content">
                    <h4 className="title">Phone</h4>

                    <em>(+91)7993606894</em>
                  </div>
                </div>
              </div>

              <ul className="social">
                <li>
                  <a>
                    <i className="fa fa-facebook"></i>
                  </a>
                </li>

                <li>
                  <a>
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>

                <li>
                  <a>
                    <i className="fa fa-pinterest-p"></i>
                  </a>
                </li>
              </ul>
            </div>
          </nav> */}

          <nav
            className="right_menu_togle mobile-navbar-menu hidden"
            id="mobile-navbar-menu"
          >
            <div className="close-btn">
              <a id="nav-close2" className="nav-close">
                <div className="line">
                  <span className="line1"></span>

                  <span className="line2"></span>
                </div>
              </a>
            </div>

            <ul className="nav-menu">
              <li className=" current-menu-item">
                <ScrollToTopLink to="/">Home</ScrollToTopLink>
              </li>

              <li className="menu-item-has-children">
                <ScrollToTopLink to="/aboutus">About Us</ScrollToTopLink>
              </li>

              <li className="menu-item-has-children has-sub">
                <ScrollToTopLink to="/services">Services</ScrollToTopLink>
                <ul className="sub-menu">
                              <li>
                                <ScrollToTopLink to="/services/fullstack-development">
                                  Full stack development
                                </ScrollToTopLink>
                              </li>
                              <li>
                                <ScrollToTopLink to="/services/e-commerce-development">
                                  e-Commerce development
                                </ScrollToTopLink>
                              </li>

                              <li>
                                <ScrollToTopLink to="/services/mobile-app-development">
                                  Mobile apps development
                                </ScrollToTopLink>
                              </li>

                              <li>
                                <ScrollToTopLink to="/services/web-app-development">
                                  Web application development
                                </ScrollToTopLink>
                              </li>

                              <li>
                                <ScrollToTopLink to="/services/hire-a-developer">
                                  Hire a developer
                                </ScrollToTopLink>
                              </li>

                              <li>
                                <ScrollToTopLink to="/services/technical-support">
                                  Technical support service
                                </ScrollToTopLink>
                              </li>
                              <li>
                                <ScrollToTopLink to="/services/bi-analytics">
                                  Data & Analytics
                                </ScrollToTopLink>
                              </li>
                              <li>
                                <ScrollToTopLink to="/services/ai-ml">
                                  AI & ML
                                </ScrollToTopLink>
                              </li>
                              <li>
                                <ScrollToTopLink to="/services/erp-solutions">
                                  ERP Solutions
                                </ScrollToTopLink>
                              </li>
                            </ul>{" "}
                          </li>

                          {/* <li>
                            <ScrollToTopLink to="/blog" aria-hidden="true">
                              Blog
                            </ScrollToTopLink>
                          </li> */}
                          <li className="">
                            <ScrollToTopLink to="/careers">
                              Careers
                            </ScrollToTopLink>
                          </li>
              <li className="">
                <ScrollToTopLink to="/contact">Contact</ScrollToTopLink>
              </li>
            </ul>

            <div className="canvas-contact">
              <div className="address-area">
                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-location"></i>
                  </div>

                  <div className="info-content">
                    <h4 className="title">Address</h4>

                    <em>8-3-191/17, 2/A, 3rd Floor, Vengala Rao Nagar, S.R Nagar, Hyderabad-38.</em>
                  </div>
                </div>

                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-email"></i>
                  </div>

                  <div className="info-content">
                    <h4 className="title">E-mail</h4>

                    <em>
                      <a href="mailto:info@hiltonglobal.in">
                        info@hiltonglobal.in
                      </a>
                    </em>
                  </div>
                </div>

                <div className="address-list">
                  <div className="info-icon">
                    <i className="flaticon-call"></i>
                  </div>

                  <div className="info-content">
                    <h4 className="title">Phone</h4>
                    <em><a href="tel:+917993606894">+91 79936 06894</a></em>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>
        <div
          id="scrollUp"
          style={{ display: scrollY > 150 ? "block" : "none" }}
          className="paste-color"
          onClick={handleScrollTop}
        >
          <i className="fa fa-angle-up"></i>
        </div>
      </div>
    </div>
  );
};
export default Header;
