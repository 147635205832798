import Header from "../Header";
import Footer from "../Footer";
import BreadCrums from "../BreadCrums";
import ClientSection from "../ClientSection";
import { clientslider } from "../CarosuelsSettings";
import MetaTag from "../MetaTag";

const HireADeveloper = () => {
  const MetaTitle = "Hire A Software Developer - Developer As A Service - Outsource Developer | Hilton";
  const MetaDescription =
    "You can test the developer by assigning him a sample task or pilot project before hiring. You can hire expert web developers, web designers, digital marketing experts...";
  const MetaKeywords =
    "Hire A Software Developer, Hire a web developer, Software as a service, Outsource developer";
  const path = window.location.pathname;

  return (
    <>
      <Header />
      <MetaTag
        title={MetaTitle}
        description={MetaDescription}
        keywords={MetaKeywords}
        path={path}
      />
      <BreadCrums
        name={"hire-developer"}
        tittle={"Hire a developer"}
        watermark={"Hire a developer"}
      />
      <div id="" className="fulfill-ecommerce pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30 bg-black">
        <div className="container-fluid custom">
          <div className="row y-middle">
            <div className="col-md-6">
            <div className="sec-title3 mb-55 md-mb-30">
            <h1 className="title title2">
              Hire skilled & vetted developers in India
            </h1>
            <div class="bar-main">
                  <div class="bar bar-big left"></div>
             </div>
            </div>
            <p className="services-txt ft16 text-white">
            Hilton Technologies provides a valuable opportunity to hire highly skilled developers from India for long-term, short-term, or hourly-based projects. Our team of qualified professionals delivers exceptional solutions with precision and expertise, tailored to meet the unique needs of your projects.
              </p>
            </div>
          <div className="col-md-6">
            <div  className="dt-sc-circular-icon-set">
          <div class="wpb_wrapper">
                    <div class="dt-sc-icon-box type5 custom-style-2 " data-delay="0">
                      <div class="icon-wrapper">
                        <img loading="lazy" decoding="async" width="33" height="33" src="/assets/images/quality.png" class="attachment-full" alt="" title="icon-box-1"/>
                        </div>
                        <div class="icon-content">
                          <h5>High quality work</h5>
                      </div>
                  </div>
                      <div class="dt-sc-icon-box type5 custom-style-2 " data-delay="0">
                        <div class="icon-wrapper">
                          <img loading="lazy" decoding="async" width="33" height="33" src="/assets/images/safety.png" class="attachment-full" alt="" title="icon-box-3"/>
                          </div>
                          <div class="icon-content">
                            <h5>Data security</h5>
                            </div>
                            </div>
                            <div class="dt-sc-icon-box type5 custom-style-2 " data-delay="0"><div class="icon-wrapper"><img loading="lazy" decoding="async" width="33" height="33" src="/assets/images/growth.png" class="attachment-full" alt="" title="icon-box-4"/>
                            </div>
                            <div class="icon-content">
                              <h5>Quick team scaling</h5>
                              </div>
                              </div>
                              <div class="dt-sc-icon-box type5 custom-style-2 " data-delay="0"><div class="icon-wrapper"><img loading="lazy" decoding="async" width="33" height="33" src="/assets/images/coding.png" class="attachment-full" alt="" title="icon-box-4"/>
                            </div>
                            <div class="icon-content">
                              <h5>Certified developers</h5>
                              </div>
                              </div>
                              <div class="dt-sc-icon-box type5 custom-style-2 last " data-delay="0">
                                <div class="icon-wrapper">
                                  <img loading="lazy" decoding="async" width="33" height="33" src="/assets/images/troubleshooting.png" class="attachment-full" alt="" title="icon-box-5"/>
                                  </div>
                                  <div class="icon-content">
                                    <h5>Inclusive work and easy troubleshooting</h5>
                                    </div>
                                    </div>
                                    </div>
                                    </div>
              <div className="row hire-points hidden">
                <div className="col-sm-4">
                  <p>
                    <i className="fa fa-thumbs-up"></i>High quality work
                  </p>
                </div>

                <div className="col-sm-4">
                  <p>
                    <i className="fa fa-thumbs-up"></i>Data security
                  </p>
                </div>

                <div className="col-sm-4">
                  <p>
                    <i className="fa fa-thumbs-up"></i>Quick team scaling
                  </p>
                </div>

                <div className="col-sm-4">
                  <p>
                    <i className="fa fa-thumbs-up"></i>Certified developers
                  </p>
                </div>

                <div className="col-sm-4">
                  <p>
                    <i className="fa fa-thumbs-up"></i>Inclusive work and easy
                    troubleshooting
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="" className="fulfill-ecommerce pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30 hidden">
        <div className="container custom">
          <div className="sec-title text-center mb-55 md-mb-30">
            <h2 className="title title2">
              Hire skilled & vetted developers in India
            </h2>
          </div>

          <div className="row y-middle">
            <div className="col-md-12 text-center">
              <p className="services-txt">
                Hilton technologies offers a prime opportunity to engage highly
                skilled developers from India for long-term, short-term, or
                hourly-based projects. With a team of qualified developers,
                Hilton delivers exceptional solutions with precision and
                expertise to meet your unique project requirements.
              </p>

              <div className="row hire-points">
                <div className="col-sm-4">
                  <p>
                    <i className="fa fa-thumbs-up"></i>High quality work
                  </p>
                </div>

                <div className="col-sm-4">
                  <p>
                    <i className="fa fa-thumbs-up"></i>Data security
                  </p>
                </div>

                <div className="col-sm-4">
                  <p>
                    <i className="fa fa-thumbs-up"></i>Quick team scaling
                  </p>
                </div>

                <div className="col-sm-4">
                  <p>
                    <i className="fa fa-thumbs-up"></i>Certified developers
                  </p>
                </div>

                <div className="col-sm-4">
                  <p>
                    <i className="fa fa-thumbs-up"></i>Inclusive work and easy
                    troubleshooting
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <div
          id="rs-iconbox-area"
          className="rs-iconbox-area pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30 bg-pattern"
        >
          <div className="container-fluid custom">
           

            <div className="row y-middle">
            <div className="col-sm-3">

            </div>
              <div className="col-sm-9">
              <div className="sec-title mb-55 md-mb-30">
              <h2 className="title title2">
                Hire dedicated developers in India
              </h2>
              <div class="bar-main">
                  <div class="bar bar-big left"></div>
             </div>
            </div>
              <div className="row">
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/React JS.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt"> Hire ReactJs Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/Angular.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt"> Hire Angular Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/React JS.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt"> Hire React Native Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/HTML CSS.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt">Hire HTML/CSS Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/PHP.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt">Hire PHP Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/Laravel.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt">Hire Laravel Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/Node JS.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt">Hire Node.Js Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/CakePHP.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt">Hire CakePHP Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/python.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt">Hire Python Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/Magento.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt">Hire Magento Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/shopify.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>
                    <div className="text-area">
                      <p className="services-txt">Hire Shopify Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/woocommerce.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt">Woo-Commerce Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/Wordpress.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt">Hire Wordpress Developer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/DevOps.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt">Hire DevOps Engineer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/tech-support.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt">Hire Technical Support Engineer</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/power-bi.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt">Hire Power BI Developer</p>
                    </div>
                  </div>
                </div>
              </div>
             
              
              {/* <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/bootstrap.webp"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt">Hire Bootstrap developer</p>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="col-lg-3 col-md-6 mb-10">
                <div className="box-inner">
                  <div className="box-item">
                    <div className="icon-box">
                      <a>
                        <img
                          className="dance_hover"
                          src="/assets/images/hire-a-developer/aws.jpeg"
                          alt="Partner"
                        />
                      </a>
                    </div>

                    <div className="text-area">
                      <p className="services-txt">Hire AWS architecture</p>
                    </div>
                  </div>
                </div>
              </div> */}
              </div>
              </div>
              
            </div>
          </div>
        </div>
        <div
        id="process-steps"
        className="mob-dev style4 pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30 bg-black"
      >
        <div className="container-fluid">
          <div className="sec-title3 text-center mb-60">
            <h2 className="title title2 pb-20">
              How to hire developers through Hilton
            </h2>
                <span className="border-line">
                  <i className="fa fa-diamond" id="diamond"></i>
                  </span>
            {/* <p className="dese margin-0">
              Hilton offers end-to-end mobile app development services, led by a
              team of experienced professionals skilled in the latest
              technologies.
            </p> */}
            {/* <div class="bar-main">
                  <div class="bar bar-big"></div>
             </div> */}
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="process-steps">
                <div className="process-item bottom">
                    <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/design-thinking.webp" className="attachment-full dance_hover" alt="Talk to one of our industry experts" title="Talk to one of our industry experts" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Talk to one of our industry experts</h3>
                      <p>A Hilton's director of engineering will work with you to understand your goals, technical needs, and team dynamics.</p>
                    </div>
               </div>
               <div className="process-item top">
                    <div className="icon-wrapper reverse">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/development.webp" className="attachment-full dance_hover" alt="Work with hand-selected talent" title="Work with hand-selected talent" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">Work with hand-selected talent</h3>
                      <p>Within days, we'll introduce you to the right developer for your project. Average time to hire should not be less than 40 hours.</p>
                    </div>
               </div>
               <div className="process-item">
                   <div className="pt-150 sm-pt-0"></div>
                    <div className="icon-wrapper">
                        <span>
                            <img loading="lazy" decoding="async" width="95" height="95" src="/assets/images/qa.webp" className="attachment-full dance_hover" alt="The right fit, guaranteed" title="The right fit, guaranteed" />
                        </span>
                    </div>
                    <div className="process-content">
                      <h3 className="title">The right fit, guaranteed</h3>
                      <p>Work with your new developer for a trial period (pay only if satisfied), ensuring they're the right fit before starting the engagement.</p>
                    </div>
               </div>
               </div>
            </div>
          </div>
        </div>
      </div>
        <div className="rs-process style1 gray-bg pt-60 pb-60 md-pt-60 md-pb-60 xs-pt-30 xs-pb-30 hidden">
          <div className="container">
            <div className="row y-middle">
              <div className="col-lg-12">
                <div className="sec-title text-center mb-30">
                  <h2 className="title title2 ">
                    How to hire developers through Hilton
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="container custom2">
            <div className="process-effects-layer">
              <div className="row">
                <div className="col-lg-4 col-md-4 md-mb-30">
                  <div className="rs-addon-number">
                    <div className="number-part">
                      <div className="number-image">
                        <img
                          src="/assets/images/process/style1/1.webp"
                          alt="Process"
                        />
                      </div>

                      <div className="number-text">
                        <div className="number-area">
                          {" "}
                          <span className="number-prefix"> 1 </span>
                        </div>

                        <div className="number-title">
                          <h3 className="title">
                            {" "}
                            Talk to one of our industry experts
                          </h3>

                          <p>
                            A Hilton's director of engineering will work with
                            you to understand your goals, technical needs, and
                            team dynamics.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 md-mb-30">
                  <div className="rs-addon-number">
                    <div className="number-part">
                      <div className="number-image">
                        <img
                          src="/assets/images/process/style1/2.webp"
                          alt="Process"
                        />
                      </div>

                      <div className="number-text">
                        <div className="number-area">
                          {" "}
                          <span className="number-prefix"> 2 </span>
                        </div>

                        <div className="number-title">
                          <h3 className="title">
                            Work with hand-selected talent
                          </h3>

                          <p>
                            Within days, we'll introduce you to the right
                            developer for your project. Average time to hire
                            should not be less than 40 hours.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-4 sm-mb-30">
                  <div className="rs-addon-number">
                    <div className="number-part">
                      <div className="number-image">
                        <img
                          src="/assets/images/process/style1/3.webp"
                          alt="Process"
                        />
                      </div>

                      <div className="number-text">
                        <div className="number-area">
                          {" "}
                          <span className="number-prefix"> 3 </span>
                        </div>

                        <div className="number-title">
                          <h3 className="title">The right fit, guaranteed</h3>

                          <p>
                            Work with your new developer for a trial period (pay
                            only if satisfied), ensuring they're the right fit
                            before starting the engagement.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*
<!-- Partner Section Start --> */}

        <div className="rs-patter-section pt-60 pb-60 xs-pt-30 xs-pb-30 bg-black2 hidden">
          <div className="container custom">
            <ClientSection settings={clientslider} />
          </div>
        </div>
        {/*
<!-- Partner Section End --> */}

      <Footer />
    </>
  );
};

export default HireADeveloper;
